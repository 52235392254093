import React, { useEffect } from 'react';
import CustomInput from '../components/CustomInput';
import { useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createColor, getAColor, resetState, updateAColor } from '../features/color/colorSlice';

const schema = Yup.object().shape({
  title: Yup.string().required('Please, Enter Product Color'),
});

const Addcolor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();  
  const getColorId = location.pathname.split('/')[3];
  const newColor = useSelector((state) => state.color);
  const { isSuccess, isError, isLoading, createdColor, colorName, updatedColor } = newColor;
  useEffect(() => {
    if (getColorId !== undefined) {
      dispatch(getAColor(getColorId));
    } else {
      dispatch(resetState());
    }
  }, [getColorId]);

  useEffect(() => {
    if (isSuccess && createdColor) {
      toast.success('Done easy!');
    } if (updatedColor && isSuccess) {
      toast.success('Done updated!');
      navigate('/admin/color-list');
    } if (isError) {
      toast.error('Sorry, Something is Wrong!');
    }
  },[isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: colorName || '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getColorId !== undefined) {
        const data = { id: getColorId, colorData: values};
        dispatch(updateAColor(data));
        dispatch(resetState())
      } else {
        dispatch(createColor(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState())
        },300);
      }
    },
  });

  return (
    <div>
      <h3 className='mb-4'>{getColorId !== undefined ? 'Edit' : 'Add'} Color</h3>
      <div>
        <form action='' onSubmit={formik.handleSubmit}>
          <CustomInput type='color' label='Enter Product Color' name='title' onCh={formik.handleChange('title')} onBlur={formik.handleBlur('title')} val={formik.values.title} />
          <div className='error mt-2'>
            {formik.touched.title && formik.errors.title}
          </div>
          <button className='btn btn-success border-0 rounded-3 my-5' type='submit'>{getColorId !== undefined ? 'Edit' : 'Add'} Color</button>
        </form>
      </div>
    </div>
  )
}

export default Addcolor

import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import { deleteAProduct, getProducts, resetState } from '../features/product/productSlice';
import { FiEdit, FiDelete } from 'react-icons/fi';
import {Link} from 'react-router-dom';
import CustomModal from '../components/CustomModal';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.title.length - b.title.length,
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.brand.length - b.brand.length,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.category.length - b.category.length,
  },
  {
    title: 'Color',
    dataIndex: 'color',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.price.length - b.price.length,
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
];

const Productlist = () => {
  const [open, setOpen] = useState(false);
  const [prodId, setprodId] = useState('');
  const showModal = (e) => {
    setOpen(true);
    setprodId(e);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getProducts());
  }, []);
  const productState = useSelector((state) => state.product.products);
  const data1 = [];
  for (let i = 0; i < productState.length; i++) {
    data1.push({
      key: i + 1,
      title: productState[i].title,
      brand: productState[i].brand,
      category: productState[i].category,
      color: productState[i].color, //Color is sometimes created as Object _id in DB ?????? -> not working
      price: `Bir ${productState[i].price}`,
      action: <><Link to={`/admin/product/${productState[i]._id}`}><FiEdit /></Link><button onClick={() => showModal(productState[i]._id)} className='ms-3 text-danger bg-transparent border-0'><FiDelete /></button></>,
    });
  }

  const deleteProduct = (e) => {
    setOpen(false);
    dispatch(deleteAProduct(e));
    setTimeout(() => {
      dispatch(getProducts());
    },200);
  };

  return (
    <div>
      <h3 className='mb-4'>Product Lists</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal hideModal={hideModal} open={open} title='Are you sure to delete it?' action={() => {deleteProduct(prodId)}} />
    </div>
  )
} 

export default Productlist

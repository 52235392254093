import React, { useEffect } from 'react';
import CustomInput from '../components/CustomInput';
import { useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createCoupon, getACoupon, resetState, updateACoupon } from '../features/coupon/couponSlice';


const schema = Yup.object().shape({
  name: Yup.string().required('Please, Enter Coupon Name'),
  discount: Yup.number().required('Please, Enter Discont Percentage'),
  expiry: Yup.date().required('Please, Enter Expiry Date'),
});

const AddCoupon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();  
  const getCouponId = location.pathname.split('/')[3];
  const newCoupon = useSelector((state) => state.coupon);
  const { isSuccess, isError, isLoading, createdCoupon, couponName, couponExpiry, couponDiscount, updatedCoupon } = newCoupon;
  const changeDateFormat = (date) => {
    const newDate = new Date(date).toLocaleDateString();
    const [month, day, year] = newDate.split('/');
    return [year, month, day].join('-');
  };

  useEffect(() => {
    if (getCouponId !== undefined) {
      dispatch(getACoupon(getCouponId));
    } else {
      dispatch(resetState());
    }
  }, [getCouponId]);

  useEffect(() => {
    if (isSuccess && createdCoupon) {
      toast.success('Done easy!');
    }  if ( isSuccess && updatedCoupon) {
      toast.success('Done updated!');
      navigate('/admin/coupon-list');
    } if (isError && couponName && couponDiscount && couponExpiry) {
      toast.error('Sorry, Something is Wrong!');
    }
  },[isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: couponName || '',
      expiry: changeDateFormat(couponExpiry) || '',
      discount: couponDiscount || '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getCouponId !== undefined) {
        const data = { id: getCouponId, couponData: values};
        dispatch(updateACoupon(data));
        dispatch(resetState())
      } else {
        dispatch(createCoupon(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState())
        },300);
      }
    },
  });

  return (
    <div>
      <h3 className='mb-4'>{getCouponId !== undefined ? 'Edit' : 'Add'} Coupon</h3>
      <div>
        <form action='' onSubmit={formik.handleSubmit}>
          <CustomInput type='text' id='name' label='Enter Coupon Name' name='name' onCh={formik.handleChange('name')} onBlur={formik.handleBlur('name')} val={formik.values.name} />
          <div className='error mt-2'>
            {formik.touched.name && formik.errors.name}
          </div>
          <CustomInput type='date' id='date' label='Enter Expiry Date' name='expiry' onCh={formik.handleChange('expiry')} onBlur={formik.handleBlur('expiry')} val={formik.values.expiry} />
          <div className='error mt-2'>
            {formik.touched.expiry && formik.errors.expiry}
          </div>
          <CustomInput type='number' id='discount' label='Enter Discount' name='discount' onCh={formik.handleChange('discount')} onBlur={formik.handleBlur('discount')} val={formik.values.discount} />
          <div className='error mt-2'>
            {formik.touched.discount && formik.errors.discount}
          </div>
          <button className='btn btn-success border-0 rounded-3 my-5' type='submit'>{getCouponId !== undefined ? 'Edit' : 'Add'} Coupon</button>
        </form>
      </div>
    </div>
  )
}

export default AddCoupon

import React, { useEffect } from 'react';
import CustomInput from '../components/CustomInput';
import { useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { createBrand, getABrand, resetState, updateABrand } from '../features/brand/brandSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const schema = Yup.object().shape({
  title: Yup.string().required('Please, Enter Brand Name'),
});

const Addbrand = () => {
  const dispatch = useDispatch();
  const location = useLocation();  
  const getBrandId = location.pathname.split('/')[3];
  const newBrand = useSelector((state) => state.brand);
  const { isSuccess, isError, isLoading, createdBrand, brandName, updatedBrand } = newBrand;
  useEffect(() => {
    if (getBrandId !== undefined) {
      dispatch(getABrand(getBrandId));
    } else {
      dispatch(resetState());
    }
  }, [getBrandId]);
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess && createdBrand) {
      toast.success('Done easy!');
    }if (updatedBrand && isSuccess) {
      toast.success('Done updated!');
      navigate('/admin/list-brand');
    } if (isError) {
      toast.error('Sorry, Something is Wrong!');
    }
  },[isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: brandName || '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getBrandId !== undefined) {
        const data = { id: getBrandId, brandData: values};
        dispatch(updateABrand(data));
        dispatch(resetState())
      } else {
        dispatch(createBrand(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState())
        },300);
      }
    },
  });

  return (
    <div>
      <h3 className='mb-4'>{getBrandId !== undefined ? 'Edit' : 'Add'} Brand</h3>
      <div>
        <form action='' onSubmit={formik.handleSubmit}>
          <CustomInput type='text' label='Enter Brand Name' name='title' onCh={formik.handleChange('title')} onBlur={formik.handleBlur('title')} val={formik.values.title} />
          <div className='error mt-2'>
            {formik.touched.title && formik.errors.title}
          </div>
          <button className='btn btn-success border-0 rounded-3 my-5' type='submit'>{getBrandId !== undefined ? 'Edit' : 'Add'} Brand</button>
        </form>
      </div>
    </div>
  )
}

export default Addbrand

import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Resetpassword from './pages/Resetpassword';
import Forgotpassword from './pages/Forgotpassword';
import Mainlayout from './components/MainLayout';
import Couponlist from './pages/Couponlist';
import Colorlist from './pages/Colorlist';
import Categorylist from './pages/Categorylist';
import Brandlist from './pages/Brandlist';
import Productlist from './pages/Productlist';
import AddCoupon from './pages/AddCoupon';
import Addcolor from './pages/Addcolor';
import Addcat from './pages/Addcat';
import Addbrand from './pages/Addbrand';
import Addproduct from './pages/Addproduct';
import Profile from './pages/Profile';
import { PrivateRoutes } from './routing/PrivateRoutes';
import { OpenRoutes } from './routing/OpenRoutes';
 
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<OpenRoutes><Login/></OpenRoutes>} />
        <Route path='/reset-password' element={<Resetpassword/>} />
        <Route path='/forgot-password' element={<Forgotpassword/>} />
        <Route path='/admin' element={<PrivateRoutes><Mainlayout/></PrivateRoutes>}>
          <Route index element={<Dashboard/>}/>
          <Route path='coupon' element={<AddCoupon/>} />
          <Route path='coupon/:id' element={<AddCoupon/>} />
          <Route path='coupon-list' element={<Couponlist/>} />
          <Route path='color-list' element={<Colorlist/>} />
          <Route path='color' element={<Addcolor/>} />
          <Route path='color/:id' element={<Addcolor/>} />
          <Route path='list-category' element={<Categorylist/>} />
          <Route path='category' element={<Addcat/>} />
          <Route path='category/:id' element={<Addcat/>} />
          <Route path='list-brand' element={<Brandlist/>} />
          <Route path='brand' element={<Addbrand/>} />
          <Route path='brand/:id' element={<Addbrand/>} />
          <Route path='product' element={<Addproduct/>} />
          <Route path='product-list' element={<Productlist/>} />
          <Route path='product/:id' element={<Addproduct/>} />
          <Route path='profile' element={<Profile/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

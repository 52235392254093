import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import { FiEdit, FiDelete } from 'react-icons/fi';
import {Link} from 'react-router-dom';
import { deleteACoupon, getAllCoupon, resetState } from '../features/coupon/couponSlice';
import CustomModal from '../components/CustomModal';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: 'Expiry Date',
    dataIndex: 'date',
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.discount - b.discount,
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
];

const Couponlist = () => {
  const [open, setOpen] = useState(false);
  const [couponId, setcouponId] = useState('');
  const showModal = (e) => {
    setOpen(true);
    setcouponId(e);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getAllCoupon());
  }, []);
  const couponState = useSelector((state) => state.coupon.coupons);
  const data1 = [];
  for (let i = 0; i < couponState.length; i++) {
    data1.push({
      key: i + 1,
      name: couponState[i].name,
      date: new Date(couponState[i].expiry).toLocaleString(),
      discount: couponState[i].discount,
      action: <><Link to={`/admin/coupon/${couponState[i]._id}`}><FiEdit /></Link><button onClick={() => showModal(couponState[i]._id)} className='ms-3 text-danger bg-transparent border-0'><FiDelete /></button></>,
    });
  }
  const deleteCoupon = (e) => {
    dispatch(deleteACoupon(e));
    setOpen(false);
    setTimeout(() => {
      dispatch(getAllCoupon());
    },100);
  };
  return (
    <div>
      <h3 className='mb-4'>Coupons</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal hideModal={hideModal} open={open} title='Are you sure to delete it?' action={() => {deleteCoupon(couponId)}} />
    </div>
  )
}

export default Couponlist

import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import { FiEdit, FiDelete } from 'react-icons/fi';
import {Link} from 'react-router-dom';
import { deleteAProductCategory, getCategories, resetState } from '../features/pcategory/pcategorySlice';
import CustomModal from '../components/CustomModal';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
];


const Categorylist = () => {
  const [open, setOpen] = useState(false);
  const [pCatId, setpCatId] = useState('');
  const showModal = (e) => {
    setOpen(true);
    setpCatId(e);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getCategories());
  }, []);
  const pcatState = useSelector((state) => state.pCategory.pCategories);
  const data1 = [];
  for (let i = 0; i < pcatState.length; i++) {
    data1.push({
      key: i + 1,
      name: pcatState[i].title,
      action: <><Link to={`/admin/category/${pcatState[i]._id}`}><FiEdit /></Link><button onClick={() => showModal(pcatState[i]._id)} className='ms-3 text-danger bg-transparent border-0'><FiDelete /></button></>,
    });
  }
  const deleteProductCategory = (e) => {
    setOpen(false);
    dispatch(deleteAProductCategory(e));
    setTimeout(() => {
      dispatch(getCategories());
    },100);
  };
  return (
    <div>
      <h3 className='mb-4'>All Categories</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal hideModal={hideModal} open={open} title='Are you sure to delete it?' action={() => {deleteProductCategory(pCatId)}} />
    </div>
  )
}

export default Categorylist

import React, { useEffect } from 'react';
import CustomInput from '../components/CustomInput';
import { useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createCategory, getAProductCategory, resetState, updateAProductCategory } from '../features/pcategory/pcategorySlice';

const schema = Yup.object().shape({
  title: Yup.string().required('Please, Enter Category'),
});

const Addcat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();  
  const getPCatId = location.pathname.split('/')[3];
  const newCategory = useSelector((state) => state.pCategory);
  const { isSuccess, isError, isLoading, createdCategory, categoryName, updatedCategory } = newCategory;
  useEffect(() => {
    if (getPCatId !== undefined) {
      dispatch(getAProductCategory(getPCatId));
    } else {
      dispatch(resetState());
    }
  }, [getPCatId]);

  useEffect(() => {
    if (isSuccess && createdCategory) {
      toast.success('Done easy!');
    } if (updatedCategory && isSuccess) {
      toast.success('Done updated!');
      navigate('/admin/list-category');
    } if (isError) {
      toast.error('Sorry, Something is Wrong!');
    }
  },[isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: categoryName || '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getPCatId !== undefined) {
        const data = { id: getPCatId, pCatData: values};
        dispatch(updateAProductCategory(data));
        dispatch(resetState())
      } else {
        dispatch(createCategory(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState())
        },300);
      }
    },
  });
  
  return (
    <div>
      <h3 className='mb-4'>{getPCatId !== undefined ? 'Edit' : 'Add'} Category</h3>
      <div>
        <form action='' onSubmit={formik.handleSubmit}>
          <CustomInput type='text' label='Enter Product Category' name='title' onCh={formik.handleChange('title')} onBlur={formik.handleBlur('title')} val={formik.values.title} />
          <div className='error mt-2'>
            {formik.touched.title && formik.errors.title}
          </div>
          <button className='btn btn-success border-0 rounded-3 my-5' type='submit'>{getPCatId !== undefined ? 'Edit' : 'Add'} Category</button>
        </form>
      </div>
    </div>
  )
}

export default Addcat

import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import { deleteABrand, getBrands, resetState } from '../features/brand/brandSlice';
import { FiEdit, FiDelete } from 'react-icons/fi';
import {Link} from 'react-router-dom';
import CustomModal from '../components/CustomModal';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
];

const Brandlist = () => {
  const [open, setOpen] = useState(false);
  const [brandId, setbrandId] = useState('');
  const showModal = (e) => {
    setOpen(true);
    setbrandId(e);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getBrands());
  }, []);
  const brandState = useSelector((state) => state.brand.brands);
  const data1 = [];
  for (let i = 0; i < brandState.length; i++) {
    data1.push({
      key: i + 1,
      name: brandState[i].title,
      action: <><Link to={`/admin/brand/${brandState[i]._id}`}><FiEdit /></Link><button onClick={() => showModal(brandState[i]._id)} className='ms-3 text-danger bg-transparent border-0'><FiDelete /></button></>,
    });
  }
  const deleteBrand = (e) => {
    setOpen(false);
    dispatch(deleteABrand(e));
    setTimeout(() => {
      dispatch(getBrands());
    },100);
  };
  return (
    <div>
      <h3 className='mb-4'>Brand Lists</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal hideModal={hideModal} open={open} title='Are you sure to delete it?' action={() => {deleteBrand(brandId)}} />
    </div>
  )
}

export default Brandlist
